$(document).on('turbolinks:load', function() {
  $('body').on('change', '.cb-all-locations', function(event) {
    $('.select-locations').toggle(!this.checked)
  })

  $('body').on('change', '.cb-all-accounts', function(event) {
    $('.select-accounts').toggle(!this.checked)
  })

  $('body').on('change', '.select-role', function(event) {
    $('.manager-fields').toggle($(this).val() == 'EndCustomerManager')

    if (($(this).val() == 'EndCustomerManager')) {
      $('.account-for-manager').removeClass('d-none')
      $('.account-for-manager-field').prop('disabled', false)
      $('.account-for-admin').addClass('d-none')
      $('.account-for-admin-field').prop('disabled', true)
    }else{
      $('.account-for-admin').removeClass('d-none')
      $('.account-for-admin-field').prop('disabled', false)
      $('.account-for-manager').addClass('d-none')
      $('.account-for-manager-field').prop('disabled', true)
    }
  })

  $('body').on('blur', 'input#user_email', function(e) {
    e.preventDefault()
    $.get("/users/find_by_email?email=" + $(this).val(), { dataType: "script" })
  })

  $("body").on("change", ".enable-disable-button", function(e){
    e.stopImmediatePropagation();
    let name = $(this).data('name');
    let type = $(this).data('type');
    let checked = $(this).is(':checked');
    let url = $(this).data('url');
    let message = "";

    if (checked) {
      message = 'enable';
      $(this).prop("checked", false);
    } else {
      message = 'disable';
      $(this).prop("checked", true);
    }

    $(".enable-confirmation-info").text("Are you sure to " + message + " " + name + " ?");
    $("#enable-modal .modal-title").text(message.charAt(0).toUpperCase() + message.slice(1) + " " + type );

    $('#enable-modal').modal('show')
    $('.btn-enable-link').attr("href", url)
    $('.btn-enable-link').text(message);
  })

  $("body").on("click", ".toggle-email", function() {
    let name = $(this).data('name');
    let type = $(this).data('type');
    let status = $(this).data('active');
    let url = $(this).data('url');
    let message = "";

    if (status == true) {
      message = 'enable';
      $(this).prop("checked", false);
    } else {
      message = 'disable';
      $(this).prop("checked", true);
    }

    $(".enable-confirmation-info").text("Are you sure to " + message + " " + name + " ?");
    $(".modal-title").text(message.charAt(0).toUpperCase() + message.slice(1) + " " + type );

    $('#enable-modal').modal('show')
    $('.btn-enable-link').attr("href", url)
    $('.btn-enable-link').text(message);
  })
})
